<template>
	<div class="h-100">
		<CCard class="h-100">
			<CCardBody class="technician-form">
				<div class="shop__header">
					<h5 class="mb-5">
						{{ getTitle }}
					</h5>
				</div>
				<ValidationObserver ref="form" v-slot="{ handleSubmit }">
					<form @submit.prevent="handleSubmit(submitForm)">
						<div class="custom">
							<div class="custom__input">
								<div class="row mb-4">
									<b-img
										:src="userForm.avatarUrl || AvatarDefault"
										width="100"
										height="100"
										class="m-auto"
									/>
								</div>
								<!-- create  -->
								<div class="form-group position-relative">
									<ValidationProvider name="user_created_date" mode="lazy">
										<div class="form-group row">
											<label for="createdDate" class="col-md-2 col-12 col-form-label">{{
												$t("UsersAppFormFields.createdDate")
											}}</label>
											<div :class="['col-md-10 col-12']">
												<date-picker
													id="createdDate"
													v-model="userForm.createdDate"
													value-type="format"
													class="w-100"
													:placeholder="$t('UsersAppFormFields.dateFormat')"
													:format="formatDate"
													disabled
												/>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- name  -->
								<div class="form-group position-relative">
									<ValidationProvider name="user_name">
										<div class="form-group row">
											<label for="name" class="col-md-2 col-12 col-form-label">{{
												$t("UsersAppFormFields.name")
											}}</label>
											<div :class="['col-12 col-md-10']">
												<input
													id="name"
													v-model.trim="userForm.name"
													type="text"
													class="form-control"
													:placeholder="$t('UsersAppFormFields.name')"
													autocomplete="name"
													disabled
												/>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- phone number -->
								<div class="form-group position-relative">
									<ValidationProvider name="user_phone">
										<div class="form-group row">
											<label for="phone" class="col-md-2 col-12 col-form-label">{{
												$t("UsersAppFormFields.phone")
											}}</label>
											<div :class="['col-md-10 col-12']">
												<input
													id="phone"
													v-model.trim="userForm.phoneNumber"
													type="text"
													class="form-control"
													:placeholder="$t('UsersAppFormFields.phone')"
													autocomplete="phone"
													disabled
												/>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- Email  -->
								<div class="form-group position-relative">
									<ValidationProvider name="user_email" mode="lazy">
										<div class="form-group row">
											<label for="email" class="col-md-2 col-12 col-form-label">{{
												$t("UsersAppFormFields.email")
											}}</label>
											<div :class="['col-md-10 col-12']">
												<input
													id="email"
													v-model.trim="userForm.email"
													type="text"
													class="form-control"
													:placeholder="$t('UsersAppFormFields.email')"
													autocomplete="email"
													disabled
												/>
											</div>
										</div>
									</ValidationProvider>
								</div>
								<!-- revenue  -->
								<div class="form-group position-relative">
									<ValidationProvider name="user_email" mode="lazy">
										<div class="form-group row">
											<label for="email" class="col-md-2 col-12 col-form-label">{{
												$t("UsersAppFormFields.revenue")
											}}</label>
											<div :class="['col-md-10 col-12']">
												<input
													id="revenue"
													v-model.trim="userForm.revenue"
													type="text"
													class="form-control"
													:placeholder="$t('UsersAppFormFields.revenue')"
													autocomplete="revenue"
													disabled
												/>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- status  -->
								<div class="form-group position-relative">
									<ValidationProvider ref="status" name="user_status" mode="lazy">
										<div class="form-group row">
											<label for="email" class="col-md-2 col-12 col-form-label">{{
												$t("UsersAppFormFields.status")
											}}</label>
											<div :class="['col-md-10 col-12']">
												<multiselect
													id="status"
													v-model="userForm.status"
													:options="listStatus"
													label="name"
													track-by="value"
													:close-on-select="true"
													:show-labels="false"
													:allow-empty="true"
													:searchable="true"
													:placeholder="$t('Label.status')"
													:disabled="checkDisableStatus"
												>
													<template #noOptions>
														{{ $t("DropDown.NoOptions") }}
													</template>
													<template #noResult>
														{{ $t("DropDown.NoResult") }}
													</template>
												</multiselect>
											</div>
										</div>
									</ValidationProvider>
								</div>
								<!-- rule  -->
								<div class="form-group position-relative">
									<ValidationProvider name="user_role" mode="lazy">
										<div class="form-group row">
											<label for="email" class="col-md-2 col-12 col-form-label">{{
												$t("UsersAppFormFields.rule")
											}}</label>
											<div :class="['col-md-10 col-12']">
												<textarea
													id="rule"
													v-model.trim="userForm.rule"
													type="text"
													rows="4"
													class="form-control"
													:placeholder="$t('UsersAppFormFields.rule')"
													autocomplete="rule"
													:maxlength="USER_MANAGEMENT_ROLE.RULE"
												/>
											</div>
										</div>
									</ValidationProvider>
								</div>
							</div>
						</div>

						<CRow class="text-right">
							<CCol>
								<button type="button" class="btn btn-outline-danger" @click="cancel">
									{{ $t("Button.Cancel") }}
								</button>
								<button class="px-4 ml-2 btn btn-outline-custom" type="submit">
									{{ $t("Button.Save") }}
								</button>
							</CCol>
						</CRow>
					</form>
				</ValidationObserver>
			</CCardBody>
		</CCard>
		<!-- <CustomModal
			v-if="confirmStatus"
			:title="getTitleModal()"
			:submit-text="$t('Button.Yes')"
			@submit-modal="confirm"
			@cancel-modal="cancelStatus"
		>
			<template #content>
				{{ getBodyModal() }}
			</template>
		</CustomModal> -->
	</div>
</template>

<script>
import AvatarDefault from "@/assets/img/avatar_default.jpg"
import {
	DATE_VN,
	USER_STATUS,
	USER_MANAGEMENT_ROLE,
	USER_ROLE,
} from "../../../shared/plugins/constants"
import { createNamespacedHelpers } from "vuex"
import { GET_USER_APP, UPDATE_USER_APP } from "../store/action-types"
const { mapActions, mapState } = createNamespacedHelpers("userApp")
const { mapState: authState } = createNamespacedHelpers("authentication")
export default {
	name: "UserAppDetail",
	props: {
		id: {
			type: [Number, String],
			default: null,
		},
	},
	data() {
		return {
			AvatarDefault,
			userForm: {
				status: null,
				email: null,
				createdDate: null,
				name: null,
				systemName: null,
				phoneNumber: null,
				rule: null,
				revenue: null,
				avatarUrl: null,
			},
			formatDate: DATE_VN,
			USER_MANAGEMENT_ROLE,
			listStatus: [
				{
					name: this.$t("UsersAppFilter.active"),
					value: USER_STATUS.ACTIVE,
				},
				{
					name: this.$t("UsersAppFilter.inactive"),
					value: USER_STATUS.DEACTIVATE,
				},
			],
		}
	},
	computed: {
		...mapState(["userDetail"]),
		...authState(["currentUser"]),
		getTitle() {
			return `${this.userDetail?.name} ${this.$t("UsersTitle.update")}`
		},
		checkDisableStatus() {
			if (
				this.currentUser.role === USER_ROLE.SUPER_ADMIN &&
				this.userDetail?.role !== USER_ROLE.SUPER_ADMIN
			)
				return false

			if (
				this.userDetail?.role !== USER_ROLE.SUPER_ADMIN &&
				this.userDetail?.role !== USER_ROLE.ADMIN
			) {
				return false
			}
			return true
		},
	},
	watch: {
		userDetail: {
			handler() {
				this.setData()
			},
			deep: true,
		},
	},
	created() {
		this.getUserAppDetail()
	},
	methods: {
		...mapActions({ GET_USER_APP, UPDATE_USER_APP }),
		setData() {
			if (this.userDetail) {
				this.userForm = { ...this.userDetail }
				this.userForm.createdDate = this.convertDateToDateVN(this.userDetail.createdDate)
				this.userForm.status = this.listStatus.find(
					status => status.value === this.userDetail.status,
				)
				this.userForm.revenue = this.formatNumber(this.userForm.revenue)
				this.userForm.phoneNumber = this.setPhoneNumber(this.userDetail)
			}
		},
		async submitForm() {
			const params = {
				id: this.id,
				status: this.userForm.status.value,
				rule: this.userForm.rule,
			}
			await this.UPDATE_USER_APP(params)
		},
		cancel() {
			this.$router.go(-1)
		},
		async getUserAppDetail() {
			const params = {
				id: this.id,
			}
			await this.GET_USER_APP(params)
		},
		formatNumber(n) {
			if (!n) return 0
			// format number 1000000 to 1,234,567
			return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
		},
		setPhoneNumber(user) {
			if (!user.phoneNumber) return null
			if (user.role === USER_ROLE.USER_MEMBER) {
				return `+${user.phoneCode}${user.phoneNumber}`
			}
			return `+84${user.phoneNumber}`
		},
	},
}
</script>
<style lang="scss" scoped>
.custom__input {
	width: 100%;
}
</style>
